<template>
	<div class="franchise-mobile width100Per column-me row-center" v-if="isMobile">
		<div class="row-me center-all margin-top50">
			<img src="../assets/img/title_line.png" class="img-line">
			<div class="column-me row-center margin-left10 margin-right10">
				<div class="title">加盟中心</div>
			</div>
			<img src="../assets/img/title_line.png" class="img-line">
		</div>
		<div class="subtitle">FRANCHISE</div>
		<img src="../assets/img/meng/line-logo.png" class="img-logo">
		<div class="logo-text">儿童卡丁车运动竞技平台&emsp;CCTV上榜品牌</div>
		<div class="column-me margin-top50 width100Per">
			<el-image :src="require('../assets/img/meng/img1.png')" fit="cover" hide-on-click-modal
				:preview-src-list="[require('../assets/img/meng/img1.png')]" class="img1">
			</el-image>
			<div class="column-me margin-top20">
				<el-image :src="require('../assets/img/meng/img2.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img2.png')]" class="img2">
				</el-image>
				<el-image :src="require('../assets/img/meng/img3.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img3.png')]" class="img3 margin-top20">
				</el-image>
			</div>
		</div>
		<div class="row-me row-center margin-top60">
			<img src="../assets/img/meng/1.png" class="img-xu">
			<div class="line-fen" style="background-color: #FFF9EC;">
				<div class="fen-text">创新卡丁车新模式，低成本开店</div>
			</div>
		</div>
		<div class="row-me row-center margin-top20">
			<img src="../assets/img/meng/2.png" class="img-xu">
			<div class="line-fen" style="background-color: #FEF2E0;">
				<div class="fen-text">全新运营理念，多元化盈利模式</div>
			</div>
		</div>
		<div class="row-me row-center margin-top20">
			<img src="../assets/img/meng/3.png" class="img-xu">
			<div class="line-fen" style="background-color: #FEEDE0;">
				<div class="fen-text">针对合伙人赋能输出，全面运营培训</div>
			</div>
		</div>
		<div class="row-me row-center margin-top20">
			<img src="../assets/img/meng/4.png" class="img-xu">
			<div class="line-fen" style="background-color: #FEEAE0;">
				<div class="fen-text">自主专利研发/创新运营模式/<br>赛事培训研学融合</div>
			</div>
		</div>
		<div class="row-me row-center margin-top20">
			<img src="../assets/img/meng/5.png" class="img-xu">
			<div class="line-fen" style="background-color: #FEE1E0;">
				<div class="fen-text">打造国内儿童卡丁车新体验</div>
			</div>
		</div>
		<div class="width100Per column-me row-center margin-top100" style="background: #000000;">
			<div class="row-me center-all margin-top90">
				<img src="../assets/img/title_line2.png" class="img-line">
				<div class="column-me row-center margin-left10 margin-right10">
					<div class="title" style="color: white;">五大类场馆</div>
				</div>
				<img src="../assets/img/title_line2.png" class="img-line">
			</div>
			<div class="subtitle">FIVE CATEGORIES OF VENUES</div>
			<div class="subtitle margin-top50" style="color: #CCCCCC;">提供多层级场地方案&emsp;打造沉浸式驾驶体验&emsp;满足不同场所与用户需求</div>
			<div class="row-me row-center flex-wrap margin-top40">
				<div class="item-title">室内竞速馆</div>
				<div class="item-title">主题乐园馆</div>
				<div class="item-title">室外运动场</div>
				<div class="item-title">教培馆</div>
				<div class="item-title">体能拓展馆</div>
			</div>
			<div class="column-me row-center width100Per margin-top50 margin-bottom50">
				<el-image :src="require('../assets/img/meng/img4.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img4.png')]" class="img4">
				</el-image>
				<el-image :src="require('../assets/img/meng/img5.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img5.png')]" class="img4 margin-top20">
				</el-image>
				<el-image :src="require('../assets/img/meng/img6.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img6.png')]" class="img4 margin-top20">
				</el-image>
				<el-image :src="require('../assets/img/meng/img7.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img7.png')]" class="img4 margin-top20">
				</el-image>
				<el-image :src="require('../assets/img/meng/img8.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img8.png')]" class="img4 margin-top20">
				</el-image>
			</div>
		</div>
		<div class="title margin-top100">全新运营模式</div>
		<div class="subtitle1 margin-top50">业内首创&emsp;颠覆儿童卡丁车运营模式 </div>
		<div class="row-me center-all margin-top100">
			<div class="yellow-card"></div>
			<div class="title">商业模式</div>
			<div class="yellow-card"></div>
		</div>
		<div class="row-me center-all flex-wrap margin-top50">
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">研发</div>
			</div>
			<div class="item-jia">+</div>
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">生产</div>
			</div>
			<div class="item-jia">+</div>
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">培训</div>
			</div>
			<div class="item-jia">+</div>
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">运营</div>
			</div>
			<div class="item-jia">+</div>
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">推广</div>
			</div>
		</div>
		<div class="row-me center-all margin-top100">
			<div class="yellow-card"></div>
			<div class="title">车辆运行模式</div>
			<div class="yellow-card"></div>
		</div>
		<div class="column-me row-center width100Per margin-top50">
			<img src="../assets/img/meng/app.png" class="img-app">
			<div class="column-me row-center width100Per">
				<div class="posRelative car-black-div">
					<img src="../assets/img/meng/black-car.png" class="posAbsoluteAll">
					<div class="car-text posAbsoluteAll row-me row-center">
						&emsp;&emsp;红尾狐自主研发的场控APP,整合车辆操控，数据统计等多项功能，可同时控制场内所有车辆，实现单车启动、暂停、停止操作，设定车辆运行时长、运行次数，并能在车辆启动前、运行中进行最高限速调节。为提高车辆运行安全性，场控APP还可实现全场车辆一键暂定。
					</div>
				</div>
				<div class="title margin-top50">提高车辆管理效率<br>提升场馆运行安全</div>
			</div>
		</div>
		<div class="row-me center-all margin-top100">
			<div class="yellow-card"></div>
			<div class="title">创新训练课程</div>
			<div class="yellow-card"></div>
		</div>
		<div class="column-me width100Per margin-top50">
			<div class="column-me row-center flex1">
				<div class="create-title">儿童卡丁车<br>三能课程</div>
				<el-image :src="require('../assets/img/meng/img9.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img9.png')]" class="img-create">
				</el-image>
				<div class="create-title2">
					儿童卡丁车驾驶培训基础课程<br>
					提升儿童体能、技能、智能<br>
					全方位培养赛车手
				</div>
			</div>
			<div class="column-me row-center flex1 margin-top50">
				<div class="create-title">全尺寸卡丁车<br>STEAM构建课</div>
				<el-image :src="require('../assets/img/meng/img10.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img10.png')]" class="img-create">
				</el-image>
				<div class="create-title2">
					引入电力学、空气动力学、基础力学等<br>
					学科知识，通过动手组装卡丁车<br>
					深入了解卡丁车运行原理<br>
					提升驾驶的理论知识
				</div>
			</div>
			<div class="column-me row-center flex1 margin-top50">
				<div class="create-title">迷你卡丁车<br>创造力组装课</div>
				<el-image :src="require('../assets/img/meng/img11.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img11.png')]" class="img-create">
				</el-image>
				<div class="create-title2">
					基于红狐4号卡丁车等比设计<br>
					400多个部件创意组装<br>
					提高儿童精细操作协调性<br>
					遥控驾驶培养手眼协调
				</div>
			</div>
		</div>
		<div class="row-me center-all margin-top100">
			<div class="yellow-card"></div>
			<div class="title">加盟运营方式</div>
			<div class="yellow-card"></div>
		</div>
		<div class="column-me margin-top50 width100Per">
			<div class="column-me row-center flex1">
				<img src="../assets/img/meng/img12.png" class="img-meng">
				<div class="meng-title1 margin-top30">0元加盟</div>
				<div class="meng-title2 margin-top20">
					真正无加盟费<br>
					减少前期投入<br>
					助力项目启动
				</div>
			</div>
			<div class="column-me row-center flex1 margin-top100">
				<img src="../assets/img/meng/img13.png" class="img-meng">
				<div class="meng-title1 margin-top30">运营赋能</div>
				<div class="meng-title2 margin-top20">
					合作期间全程提供运营赋能<br>
					定期运营训练营<br>
					活动方案策划<br>
					赛事组织策划<br>
					运营策略指导
				</div>
			</div>
			<div class="column-me row-center flex1 margin-top100">
				<img src="../assets/img/meng/img14.png" class="img-meng">
				<div class="meng-title1 margin-top30">整套输出</div>
				<div class="meng-title2 margin-top20">
					运营基本设备<br>
					促销周边商品<br>
					客户服务设施
				</div>
			</div>
		</div>
		<div class="row-me center-all margin-top100">
			<div class="yellow-card"></div>
			<div class="title">运营管理平台</div>
			<div class="yellow-card"></div>
		</div>
		<div class="column-me width100Per margin-top50">
			<div class="column-me">
				<img src="../assets/img/meng/img15.png" class="img15">
				<el-image :src="require('../assets/img/meng/img16.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img16.png')]" class="img16">
				</el-image>
				<img src="../assets/img/meng/img18.png" class="img15 margin-top50 margin-left70">
				<el-image :src="require('../assets/img/meng/img20.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img20.png')]" class="img20">
				</el-image>
			</div>
			<div class="column-me margin-top50">
				<img src="../assets/img/meng/img17.png" class="img15 margin-left70">
				<el-image :src="require('../assets/img/meng/img19.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img19.png')]" class="img19">
				</el-image>
				<img src="../assets/img/meng/img29.png" class="img15 margin-top50">
				<el-image :src="require('../assets/img/meng/img35.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img35.png')]" class="img35">
				</el-image>
			</div>
		</div>
		<div class="black-div column-me row-center">
			<div class="title-white margin-top50">盈利模式</div>
			<div class="subtitle-white margin-top30">红尾狐开创的多种盈利模式<br>拓宽财富渠道&nbsp;增强客户黏性</div>
			<div class="row-me row-center width100Per flex-sb margin-top50">
				<el-image :src="require('../assets/img/meng/img36.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img36.png')]" class="img-ying">
				</el-image>
				<img src="../assets/img/meng/img38.png" class="img-ying margin-left10">
			</div>
			<div class="row-me row-center width100Per flex-sb margin-top10">
				<el-image :src="require('../assets/img/meng/img24.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img24.png')]" class="img-ying">
				</el-image>
				<el-image :src="require('../assets/img/meng/img33.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img33.png')]" class="img-ying margin-left10">
				</el-image>
			</div>
			<div class="row-me row-center width100Per flex-sb margin-top10">
				<img src="../assets/img/meng/img39.png" class="img-ying">
				<el-image :src="require('../assets/img/meng/img32.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img32.png')]" class="img-ying margin-left10">
				</el-image>
			</div>
			<div class="row-me row-center width100Per flex-sb margin-top10">
				<el-image :src="require('../assets/img/meng/img37.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img37.png')]" class="img-ying">
				</el-image>
				<img src="../assets/img/meng/img40.png" class="img-ying margin-left10">
			</div>
			<div class="row-me row-center width100Per flex-sb margin-top10">
				<el-image :src="require('../assets/img/meng/img34.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img34.png')]" class="img-ying">
				</el-image>
				<el-image :src="require('../assets/img/meng/img41.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img41.png')]" class="img-ying margin-left10">
				</el-image>
			</div>
		</div>
		<div class="row-me center-all margin-top100">
			<img src="../assets/img/title_line.png" class="img-line">
			<div class="column-me row-center margin-left10 margin-right10">
				<div class="title" style="text-align: center;">一站式开店服务模块化输出</div>
			</div>
			<img src="../assets/img/title_line.png" class="img-line">
		</div>
		<img src="../assets/img/model.png" class="img-model margin-top50">
		<div class="process-div margin-top100">
			<img src="../assets/img/meng/img42.png" class="img-bg1">
			<img src="../assets/img/meng/img43.png" class="img-bg2">
			<img src="../assets/img/meng/img48.png" class="img48">
			<div class="posAbsoluteAll">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left10 margin-right10">
						<div class="title">加盟流程</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
			</div>
		</div>
		<div class="row-me center-all margin-top100">
			<img src="../assets/img/title_line.png" class="img-line">
			<div class="column-me row-center margin-left10 margin-right10">
				<div class="title">成功案例</div>
			</div>
			<img src="../assets/img/title_line.png" class="img-line">
		</div>
		<div class="column-me margin-top80 width100Per">
			<el-image :src="img1" :preview-src-list="[img1]" fit="cover" hide-on-click-modal class="img-sucess1">
			</el-image>
			<div class="column-me margin-top20">
				<div class="column-me">
					<el-image :src="img2" :preview-src-list="[img2]" fit="cover" hide-on-click-modal
						class="img-sucess2">
					</el-image>
					<el-image :src="img3" :preview-src-list="[img3]" fit="cover" hide-on-click-modal
						class="img-sucess3 margin-top20">
					</el-image>
				</div>
				<div class="column-me margin-top20">
					<div class="column-me">
						<el-image :src="img4" :preview-src-list="[img4]" fit="cover" hide-on-click-modal
							class="img-sucess4">
						</el-image>
						<el-image :src="img6" :preview-src-list="[img6]" fit="cover" hide-on-click-modal
							class="img-sucess6 margin-top20">
						</el-image>
					</div>
					<el-image :src="img5" :preview-src-list="[img5]" fit="cover" hide-on-click-modal
						class="img-sucess5 margin-top20">
					</el-image>
				</div>
			</div>
		</div>
		<div class="column-me margin-top20 width100Per">
			<el-image :src="img7" :preview-src-list="[img7]" fit="cover" hide-on-click-modal class="img-sucess7">
			</el-image>
			<el-image :src="img8" :preview-src-list="[img8]" fit="cover" hide-on-click-modal
				class="img-sucess8 margin-top20">
			</el-image>
		</div>
	</div>
	<!-- 电脑端 -->
	<div class="franchise width100Per column-me row-center" v-else>
		<div class="row-me center-all margin-top90">
			<img src="../assets/img/title_line.png" class="img-line">
			<div class="column-me row-center margin-left60 margin-right60">
				<div class="title">加盟中心</div>
				<div class="subtitle">FRANCHISE</div>
			</div>
			<img src="../assets/img/title_line.png" class="img-line">
		</div>
		<img src="../assets/img/meng/line-logo.png" class="img-logo">
		<div class="logo-text">儿童卡丁车运动竞技平台&emsp;CCTV上榜品牌</div>
		<div class="row-me margin-top50">
			<el-image :src="require('../assets/img/meng/img1.png')" fit="cover" hide-on-click-modal
				:preview-src-list="[require('../assets/img/meng/img1.png')]" class="img1">
			</el-image>
			<div class="column-me margin-left20">
				<el-image :src="require('../assets/img/meng/img2.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img2.png')]" class="img2">
				</el-image>
				<el-image :src="require('../assets/img/meng/img3.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img3.png')]" class="img3 margin-top20">
				</el-image>
			</div>
		</div>
		<div class="row-me row-center margin-top90">
			<img src="../assets/img/meng/1.png" class="img-xu">
			<div class="line-fen" style="background-color: #FFF9EC;">
				<div class="fen-text">创新卡丁车新模式，低成本开店</div>
			</div>
		</div>
		<div class="row-me row-center margin-top50">
			<img src="../assets/img/meng/2.png" class="img-xu">
			<div class="line-fen" style="background-color: #FEF2E0;">
				<div class="fen-text">全新运营理念，多元化盈利模式</div>
			</div>
		</div>
		<div class="row-me row-center margin-top50">
			<img src="../assets/img/meng/3.png" class="img-xu">
			<div class="line-fen" style="background-color: #FEEDE0;">
				<div class="fen-text">针对合伙人赋能输出，全面运营培训</div>
			</div>
		</div>
		<div class="row-me row-center margin-top50">
			<img src="../assets/img/meng/4.png" class="img-xu">
			<div class="line-fen" style="background-color: #FEEAE0;">
				<div class="fen-text">自主专利研发/创新运营模式/赛事培训研学融合</div>
			</div>
		</div>
		<div class="row-me row-center margin-top50">
			<img src="../assets/img/meng/5.png" class="img-xu">
			<div class="line-fen" style="background-color: #FEE1E0;">
				<div class="fen-text">打造国内儿童卡丁车新体验</div>
			</div>
		</div>
		<div class="width100Per column-me row-center margin-top100" style="background: #000000;">
			<div class="row-me center-all margin-top90">
				<img src="../assets/img/title_line2.png" class="img-line">
				<div class="column-me row-center margin-left60 margin-right60">
					<div class="title" style="color: white;">五大类场馆</div>
					<div class="subtitle">FIVE CATEGORIES OF VENUES</div>
				</div>
				<img src="../assets/img/title_line2.png" class="img-line">
			</div>
			<div class="subtitle margin-top50" style="color: #CCCCCC;">提供多层级场地方案&emsp;打造沉浸式驾驶体验&emsp;满足不同场所与用户需求</div>
			<div class="row-me row-center margin-top40">
				<div class="item-title">室内竞速馆</div>
				<div class="item-title">主题乐园馆</div>
				<div class="item-title">室外运动场</div>
				<div class="item-title">教培馆</div>
				<div class="item-title">体能拓展馆</div>
			</div>
			<div class="row-me row-center margin-top50 margin-bottom80">
				<el-image :src="require('../assets/img/meng/img4.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img4.png')]" class="img4">
				</el-image>
				<el-image :src="require('../assets/img/meng/img5.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img5.png')]" class="img4 margin-left20">
				</el-image>
				<el-image :src="require('../assets/img/meng/img6.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img6.png')]" class="img4 margin-left20">
				</el-image>
				<el-image :src="require('../assets/img/meng/img7.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img7.png')]" class="img4 margin-left20">
				</el-image>
				<el-image :src="require('../assets/img/meng/img8.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img8.png')]" class="img4 margin-left20">
				</el-image>
			</div>
		</div>
		<div class="title margin-top150">全新运营模式</div>
		<div class="subtitle1 margin-top80">业内首创&emsp;颠覆儿童卡丁车运营模式 </div>
		<div class="row-me center-all margin-top100">
			<div class="yellow-card"></div>
			<div class="title">商业模式</div>
			<div class="yellow-card"></div>
		</div>
		<div class="row-me row-center margin-top100">
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">研发</div>
			</div>
			<div class="item-jia">+</div>
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">生产</div>
			</div>
			<div class="item-jia">+</div>
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">培训</div>
			</div>
			<div class="item-jia">+</div>
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">运营</div>
			</div>
			<div class="item-jia">+</div>
			<div class="item-yuan">
				<img src="../assets/img/meng/yuan.png" class="posAbsoluteAll">
				<div class="item-yuan-title posAbsoluteAll">推广</div>
			</div>
		</div>
		<div class="row-me center-all margin-top150">
			<div class="yellow-card"></div>
			<div class="title">车辆运行模式</div>
			<div class="yellow-card"></div>
		</div>
		<div class="row-me row-center margin-top100">
			<img src="../assets/img/meng/app.png" class="img-app">
			<div class="column-me row-center flex1 margin-left50">
				<div class="posRelative car-black-div">
					<img src="../assets/img/meng/black-car.png" class="posAbsoluteAll">
					<div class="car-text posAbsoluteAll row-me row-center">
						&emsp;&emsp;红尾狐自主研发的场控APP,整合车辆操控，数据统计等多项功能，可同时控制场内所有车辆，实现单车启动、暂停、停止操作，设定车辆运行时长、运行次数，并能在车辆启动前、运行中进行最高限速调节。为提高车辆运行安全性，场控APP还可实现全场车辆一键暂定。
					</div>
				</div>
				<div class="title margin-top80">提高车辆管理效率&emsp;提升场馆运行安全</div>
			</div>
		</div>
		<div class="row-me center-all margin-top150">
			<div class="yellow-card"></div>
			<div class="title">创新训练课程</div>
			<div class="yellow-card"></div>
		</div>
		<div class="row-me margin-top100">
			<div class="column-me row-center flex1">
				<div class="create-title">儿童卡丁车<br>三能课程</div>
				<el-image :src="require('../assets/img/meng/img9.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img9.png')]" class="img-create">
				</el-image>
				<div class="create-title2">
					儿童卡丁车驾驶培训基础课程<br>
					提升儿童体能、技能、智能<br>
					全方位培养赛车手
				</div>
			</div>
			<div class="column-me row-center flex1 margin-left50">
				<div class="create-title">全尺寸卡丁车<br>STEAM构建课</div>
				<el-image :src="require('../assets/img/meng/img10.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img10.png')]" class="img-create">
				</el-image>
				<div class="create-title2">
					引入电力学、空气动力学、基础力学等<br>
					学科知识，通过动手组装卡丁车<br>
					深入了解卡丁车运行原理<br>
					提升驾驶的理论知识
				</div>
			</div>
			<div class="column-me row-center flex1 margin-left50">
				<div class="create-title">迷你卡丁车<br>创造力组装课</div>
				<el-image :src="require('../assets/img/meng/img11.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img11.png')]" class="img-create">
				</el-image>
				<div class="create-title2">
					基于红狐4号卡丁车等比设计<br>
					400多个部件创意组装<br>
					提高儿童精细操作协调性<br>
					遥控驾驶培养手眼协调
				</div>
			</div>
		</div>
		<div class="row-me center-all margin-top150">
			<div class="yellow-card"></div>
			<div class="title">加盟运营方式</div>
			<div class="yellow-card"></div>
		</div>
		<div class="row-me margin-top100">
			<div class="column-me row-center flex1">
				<img src="../assets/img/meng/img12.png" class="img-meng">
				<div class="meng-title1 margin-top30">0元加盟</div>
				<div class="meng-title2 margin-top20">
					真正无加盟费<br>
					减少前期投入<br>
					助力项目启动
				</div>
			</div>
			<div class="column-me row-center flex1 margin-left220">
				<img src="../assets/img/meng/img13.png" class="img-meng">
				<div class="meng-title1 margin-top30">运营赋能</div>
				<div class="meng-title2 margin-top20">
					合作期间全程提供运营赋能<br>
					定期运营训练营<br>
					活动方案策划<br>
					赛事组织策划<br>
					运营策略指导
				</div>
			</div>
			<div class="column-me row-center flex1 margin-left220">
				<img src="../assets/img/meng/img14.png" class="img-meng">
				<div class="meng-title1 margin-top30">整套输出</div>
				<div class="meng-title2 margin-top20">
					运营基本设备<br>
					促销周边商品<br>
					客户服务设施
				</div>
			</div>
		</div>
		<div class="row-me center-all margin-top150">
			<div class="yellow-card"></div>
			<div class="title">运营管理平台</div>
			<div class="yellow-card"></div>
		</div>
		<div class="row-me margin-left100 margin-right100 margin-top100">
			<div class="column-me">
				<img src="../assets/img/meng/img15.png" class="img15">
				<el-image :src="require('../assets/img/meng/img16.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img16.png')]" class="img16">
				</el-image>
				<img src="../assets/img/meng/img18.png" class="img15 margin-top50 margin-left200">
				<el-image :src="require('../assets/img/meng/img20.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img20.png')]" class="img20">
				</el-image>
			</div>
			<div class="column-me margin-left80">
				<img src="../assets/img/meng/img17.png" class="img15 margin-left200">
				<el-image :src="require('../assets/img/meng/img19.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img19.png')]" class="img19">
				</el-image>
				<img src="../assets/img/meng/img29.png" class="img15 margin-top50 margin-left30">
				<el-image :src="require('../assets/img/meng/img35.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img35.png')]" class="img35">
				</el-image>
			</div>
		</div>
		<div class="black-div column-me row-center margin-left100 margin-right100">
			<div class="title-white margin-top50">盈利模式</div>
			<div class="subtitle-white margin-top30">红尾狐开创的多种盈利模式&emsp;拓宽财富渠道&nbsp;增强客户黏性</div>
			<div class="row-me row-center width100Per flex-sb margin-top50">
				<el-image :src="require('../assets/img/meng/img36.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img36.png')]" class="img-ying margin-left30">
				</el-image>
				<img src="../assets/img/meng/img38.png" class="img-ying">
				<el-image :src="require('../assets/img/meng/img24.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img24.png')]" class="img-ying">
				</el-image>
				<el-image :src="require('../assets/img/meng/img33.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img33.png')]" class="img-ying">
				</el-image>
				<img src="../assets/img/meng/img39.png" class="img-ying margin-right30">
			</div>
			<div class="row-me row-center width100Per flex-sb margin-top30 margin-bottom30">
				<el-image :src="require('../assets/img/meng/img32.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img32.png')]" class="img-ying margin-left30">
				</el-image>
				<el-image :src="require('../assets/img/meng/img37.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img37.png')]" class="img-ying">
				</el-image>
				<img src="../assets/img/meng/img40.png" class="img-ying">
				<el-image :src="require('../assets/img/meng/img34.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img34.png')]" class="img-ying">
				</el-image>
				<el-image :src="require('../assets/img/meng/img41.png')" fit="cover" hide-on-click-modal
					:preview-src-list="[require('../assets/img/meng/img41.png')]" class="img-ying margin-right30">
				</el-image>
			</div>
		</div>
		<div class="row-me center-all margin-top120">
			<img src="../assets/img/title_line.png" class="img-line">
			<div class="column-me row-center margin-left60 margin-right60">
				<div class="title" style="text-align: center;">一站式开店服务<br>模块化输出</div>
			</div>
			<img src="../assets/img/title_line.png" class="img-line">
		</div>
		<div class="row-me row-center margin-top100">
			<div class="title">选址评估</div>
			<img src="../assets/img/meng/img-right.png" class="img-jiantou">
			<div class="title">场地设计</div>
			<img src="../assets/img/meng/img-right.png" class="img-jiantou">
			<div class="title">品牌宣传</div>
			<img src="../assets/img/meng/img-right.png" class="img-jiantou">
			<div class="title">装修设计</div>
		</div>
		<div class="row-me width100Per">
			<div class="chang-title">平面图、效果图、施工图</div>
			<img src="../assets/img/meng/img-bottom.png" class="img-jiantou1">
		</div>
		<div class="row-me row-center margin-top10">
			<div class="title">研学拓展</div>
			<img src="../assets/img/meng/img-left.png" class="img-jiantou">
			<div class="title">赛事组织</div>
			<img src="../assets/img/meng/img-left.png" class="img-jiantou">
			<div class="title">推广引流</div>
			<img src="../assets/img/meng/img-left.png" class="img-jiantou">
			<div class="title">开业指导</div>
		</div>
		<div class="process-div margin-top100">
			<img src="../assets/img/meng/img42.png" class="img-bg1">
			<img src="../assets/img/meng/img43.png" class="img-bg2">
			<img src="../assets/img/meng/img48.png" class="img48">
			<div class="posAbsoluteAll">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">加盟流程</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
			</div>
		</div>
		<div class="row-me center-all margin-top90">
			<img src="../assets/img/title_line.png" class="img-line">
			<div class="column-me row-center margin-left60 margin-right60">
				<div class="title">成功案例</div>
			</div>
			<img src="../assets/img/title_line.png" class="img-line">
		</div>
		<div class="row-me margin-top80">
			<el-image :src="img1" :preview-src-list="[img1]" fit="cover" hide-on-click-modal class="img-sucess1">
			</el-image>
			<div class="column-me margin-left20">
				<div class="row-me">
					<el-image :src="img2" :preview-src-list="[img2]" fit="cover" hide-on-click-modal
						class="img-sucess2">
					</el-image>
					<el-image :src="img3" :preview-src-list="[img3]" fit="cover" hide-on-click-modal
						class="img-sucess3 margin-left20">
					</el-image>
				</div>
				<div class="row-me margin-top20">
					<div class="column-me">
						<el-image :src="img4" :preview-src-list="[img4]" fit="cover" hide-on-click-modal
							class="img-sucess4">
						</el-image>
						<el-image :src="img6" :preview-src-list="[img6]" fit="cover" hide-on-click-modal
							class="img-sucess6 margin-top20">
						</el-image>
					</div>
					<el-image :src="img5" :preview-src-list="[img5]" fit="cover" hide-on-click-modal
						class="img-sucess5 margin-left20">
					</el-image>
				</div>
			</div>
		</div>
		<div class="row-me margin-top20">
			<el-image :src="img7" :preview-src-list="[img7]" fit="cover" hide-on-click-modal class="img-sucess7">
			</el-image>
			<el-image :src="img8" :preview-src-list="[img8]" fit="cover" hide-on-click-modal
				class="img-sucess8 margin-left20">
			</el-image>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				img1: require('../assets/img/meng/img46.png'),
				img2: require('../assets/img/meng/img23.png'),
				img3: require('../assets/img/meng/img45.png'),
				img4: require('../assets/img/meng/img25.png'),
				img5: require('../assets/img/meng/img44.png'),
				img6: require('../assets/img/meng/img31.png'),
				img7: require('../assets/img/meng/img47.png'),
				img8: require('../assets/img/meng/img21.png'),
				isMobile: false,
			}
		},
		// html加载完成之前
		created() {

		},
		// html加载完成后执行。
		mounted() {
			this.isMobile = sessionStorage.getItem('mobile') === '1'
		},
		// 事件方法执行
		methods: {},
		// 计算属性
		computed: {},
		// 侦听器
		watch: {},
	}
</script>

<style lang="scss" scoped>
	.franchise-mobile {
		padding-bottom: 100px;
		width: 100%;
		overflow: hidden;

		.img-line {
			width: 80px;
		}

		.title {
			font-size: 30px;
			color: #000000;
			letter-spacing: 2px;
			text-align: center;
			margin-bottom: 10px;
		}

		.subtitle {
			font-size: 18px;
			color: #666666;
			text-align: center;
		}

		.subtitle1 {
			font-size: 18px;
			color: #ADADAD;
			letter-spacing: 3px;
		}

		.img-logo {
			width: 100%;
			margin-top: 50px;
			object-fit: fill;
		}

		.logo-text {
			font-size: 28px;
			color: #010101;
			margin-top: 30px;
			text-align: center;
		}

		.img1 {
			width: 100%;
			height: auto;
		}

		.img2 {
			width: 100%;
			height: auto;
		}

		.img3 {
			width: 100%;
			height: auto;
		}

		.img-xu {
			width: 30px;
			height: 50px;
			object-fit: scale-down;
		}

		.line-fen {
			width: 270px;
			height: 50px;
			margin-left: 20px;
			transform: skew(-43deg, 0deg);
			display: flex;
			align-items: center;

			.fen-text {
				font-size: 15px;
				color: #864D03;
				word-break: keep-all;
				font-style: italic;
				transform: skew(45deg, 0deg);
				margin-left: 15px;
			}
		}

		.item-title {
			width: 150px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			border: 1px solid #FFFFFF;
			border-radius: 30px;
			font-size: 20px;
			color: #FFFFFF;
			margin: 15px;
		}

		.img4 {
			width: 100%;
			height: auto;
			object-fit: cover;
		}

		.yellow-card {
			width: 50px;
			height: 30px;
			background: #FFE100;
			transform: skew(-30deg, 0deg);
			margin: 0 15px;
		}

		.item-yuan {
			width: 60px;
			height: 60px;
			position: relative;

			.item-yuan-title {
				line-height: 60px;
				font-size: 22px;
				color: #FFFFFF;
				text-align: center;
			}
		}

		.item-jia {
			font-size: 50px;
			color: #000000;
			margin: 0 20px;
		}

		.img-app {
			width: 90%;
			height: auto;
		}

		.car-black-div {
			width: 100%;
			height: 286px;
			margin-top: 50px;

			.car-text {
				width: 80%;
				margin-left: 50px;
				font-size: 20px;
				color: #FFFFFF;
			}
		}

		.create-title {
			font-size: 30px;
			color: #000000;
			text-align: center;
		}

		.img-create {
			width: 100%;
			height: auto;
			margin-top: 30px;
			object-fit: cover;
		}

		.create-title2 {
			width: 100%;
			font-size: 20px;
			color: #696969;
			line-height: 35px;
			text-align: left;
			margin-top: 40px;
		}

		.img-meng {
			width: 120px;
			height: 120px;
		}

		.meng-title1 {
			font-size: 40px;
			color: #000000;
		}

		.meng-title2 {
			width: 100%;
			text-align: center;
			font-size: 20px;
			color: #767676;
			line-height: 30px;
			letter-spacing: 2px;
		}

		.img15 {
			width: 300px;
			height: 80px;
		}

		.img16 {
			width: 100%;
			height: auto;
		}

		.img20 {
			width: 100%;
			height: auto;
		}

		.img19 {
			width: 100%;
			height: auto;
		}

		.img35 {
			width: 100%;
			height: auto;
		}

		.black-div {
			width: 100%;
			background: #000000;
			margin-top: 100px;
		}

		.title-white {
			font-size: 35px;
			color: #FFFFFF;
		}

		.subtitle-white {
			font-size: 22px;
			color: #999999;
		}

		.img-ying {
			flex: 1;
			height: 140px;
		}

		.img-jiantou {
			width: 93px;
			height: 52px;
			object-fit: scale-down;
			margin: 0 20px;
		}

		.img-jiantou1 {
			width: 52px;
			height: 93px;
			object-fit: scale-down;
			margin-left: 27%;
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.chang-title {
			font-size: 26px;
			color: #7D7D7D;
			margin-left: 34%;
		}

		.img-model {
			width: 100%;
			height: auto;
		}

		.process-div {
			width: 100%;
			height: 600px;
			position: relative;

			.img-bg1 {
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
			}

			.img-bg2 {
				width: 100%;
				position: absolute;
				left: 0;
				bottom: 0;
			}

			.img48 {
				position: absolute;
				left: 0;
				width: 100%;
				top: 50%;
				transform: translateY(-50%);
				object-fit: fill;
			}
		}

		.img-sucess1 {
			width: 100%;
			height: auto;
		}

		.img-sucess2 {
			width: 100%;
			height: auto;
		}

		.img-sucess3 {
			width: 100%;
			height: auto;
		}

		.img-sucess4 {
			width: 100%;
			height: auto;
		}

		.img-sucess5 {
			width: 100%;
			height: auto;
		}

		.img-sucess6 {
			width: 100%;
			height: auto;
		}

		.img-sucess7 {
			width: 100%;
			height: auto;
		}

		.img-sucess8 {
			width: 100%;
			height: auto;
		}
	}

	.franchise {
		padding-bottom: 100px;

		.img-line {
			width: 145px;
			object-fit: scale-down;
			margin-bottom: 10px;
		}

		.title {
			font-size: 48px;
			color: #000000;
			letter-spacing: 5px;
		}

		.subtitle {
			font-size: 24px;
			color: #666666;
		}

		.subtitle1 {
			font-size: 26px;
			color: #ADADAD;
			letter-spacing: 3px;
		}

		.img-logo {
			width: 1110px;
			height: 65px;
			margin-top: 80px;
		}

		.logo-text {
			font-size: 60px;
			color: #010101;
			letter-spacing: 10px;
			margin-top: 30px;
		}

		.img1 {
			width: 1235px;
			height: 660px;
		}

		.img2 {
			width: 458px;
			height: 320px;
		}

		.img3 {
			width: 458px;
			height: 320px;
		}

		.img-xu {
			width: 93px;
			height: 120px;
		}

		.line-fen {
			width: 1400px;
			height: 106px;
			line-height: 106px;
			margin-left: 50px;
			margin-right: 100px;
			transform: skew(-43deg, 0deg);

			.fen-text {
				font-size: 50px;
				color: #864D03;
				font-style: italic;
				margin-left: 120px;
				transform: skew(45deg, 0deg);
				letter-spacing: 5px;
			}
		}

		.item-title {
			width: 180px;
			height: 60px;
			line-height: 60px;
			text-align: center;
			border: 1px solid #FFFFFF;
			border-radius: 30px;
			font-size: 24px;
			color: #FFFFFF;
			margin: 15px;
		}

		.img4 {
			width: 320px;
			height: 320px;
		}

		.yellow-card {
			width: 60px;
			height: 38px;
			background: #FFE100;
			transform: skew(-30deg, 0deg);
			margin: 0 30px;
		}

		.item-yuan {
			width: 145px;
			height: 145px;
			position: relative;

			.item-yuan-title {
				line-height: 145px;
				font-size: 36px;
				color: #FFFFFF;
				text-align: center;
			}
		}

		.item-jia {
			font-size: 100px;
			color: #000000;
			margin: 0 40px;
		}

		.img-app {
			width: 487px;
			height: 512px;
		}

		.car-black-div {
			width: 1136px;
			height: 286px;

			.car-text {
				width: 890px;
				font-size: 30px;
				color: #FFFFFF;
				line-height: 40px;
				margin-left: 150px;
				letter-spacing: 2px;
			}
		}

		.create-title {
			font-size: 36px;
			color: #000000;
			text-align: center;
		}

		.img-create {
			width: 436px;
			height: 436px;
			margin-top: 50px;
			object-fit: cover;
		}

		.create-title2 {
			width: 100%;
			font-size: 20px;
			color: #696969;
			line-height: 40px;
			text-align: left;
			margin-top: 40px;
		}

		.img-meng {
			width: 174px;
			height: 174px;
			object-fit: scale-down;
		}

		.meng-title1 {
			font-size: 50px;
			color: #000000;
		}

		.meng-title2 {
			width: 100%;
			text-align: left;
			font-size: 20px;
			color: #767676;
			line-height: 30px;
			letter-spacing: 2px;
		}

		.img15 {
			width: 385px;
			height: 101px;
			object-fit: scale-down;
		}

		.img16 {
			width: 760px;
			height: 370px;
		}

		.img20 {
			width: 773px;
			height: 434px;
		}

		.img19 {
			width: 796px;
			height: 494px;
		}

		.img35 {
			width: 768px;
			height: 433px;
		}

		.black-div {
			width: 89%;
			background: #000000;
			margin-top: 180px;
		}

		.title-white {
			font-size: 50px;
			color: #FFFFFF;
		}

		.subtitle-white {
			font-size: 26px;
			color: #999999;
		}

		.img-ying {
			width: 300px;
			height: 225px;
		}

		.img-jiantou {
			width: 93px;
			height: 52px;
			object-fit: scale-down;
			margin: 0 20px;
		}

		.img-jiantou1 {
			width: 52px;
			height: 93px;
			object-fit: scale-down;
			margin-left: 27%;
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.chang-title {
			font-size: 26px;
			color: #7D7D7D;
			margin-left: 34%;
		}

		.process-div {
			width: 100%;
			height: 1650px;
			position: relative;

			.img-bg1 {
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
			}

			.img-bg2 {
				width: 100%;
				position: absolute;
				left: 0;
				bottom: 0;
			}

			.img48 {
				position: absolute;
				left: 0;
				width: 100%;
				top: 50%;
				transform: translateY(-50%);
				object-fit: scale-down;
			}
		}

		.img-sucess1 {
			width: 623px;
			height: 944px;
		}

		.img-sucess2 {
			width: 513px;
			height: 385px;
		}

		.img-sucess3 {
			width: 530px;
			height: 385px;
		}

		.img-sucess4 {
			width: 482px;
			height: 208px;
		}

		.img-sucess5 {
			width: 560px;
			height: 549px;
		}

		.img-sucess6 {
			width: 480px;
			height: 320px;
		}

		.img-sucess7 {
			width: 1285px;
			height: 395px;
		}

		.img-sucess8 {
			width: 400px;
			height: 395px;
		}
	}
</style>
